import env from '@app/config/env';

import * as integration from './integration';
import * as mock from './mock';
import { SignIn, ResetPassword, GetUpdatedUserData } from './types';

export * from './types';

const selectedModule = env.REACT_APP_USE_MOCK ? mock : integration;

export const signIn: SignIn = selectedModule.signIn;
export const resetPassword: ResetPassword = selectedModule.resetPassword;
export const getUpdatedUserData: GetUpdatedUserData = selectedModule.getUpdatedUserData;
