import { createAsyncThunk, createAction, createSlice, PrepareAction, Slice } from '@reduxjs/toolkit';
import { ResetPasswordRequest, LoginRequest } from '@app/services/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import { setCompany } from '@app/store/slices/companySlice';
import { AuthModel, signIn, resetPassword, getUpdatedUserData } from '@app/services/firebase/auth';
import { deleteToken, deleteUser, deleteCompany, persistAuth, readAuth } from '@app/services/localStorage';

export type AuthSlice = AuthModel;

const initialState: AuthSlice | null = readAuth();

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) => {
  try {
    const response = await signIn(loginPayload);

    dispatch(setUser(response.user));
    dispatch(setAuth(response.auth, loginPayload.rememberMe));

    return response;
  } catch (err) {
    throw err;
  }
});

export const doUpdatedUserData = createAsyncThunk('auth/doUpdatedUserData', async (payload, { dispatch, getState }) => {
  try {
    const storeData = getState() as any;
    if (storeData?.user?.uid) {
      const response = await getUpdatedUserData(storeData?.user?.uid);
      const currentAuth = readAuth(true);

      dispatch(setUser(response.user));
      dispatch(setAuth(response.auth, Boolean(currentAuth?.token)));

      return response;
    }

    return storeData?.auth;
  } catch (err) {
    throw err;
  }
});

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload.email),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  deleteCompany();
  dispatch(setUser(null));
  dispatch(setAuth(null));
  dispatch(setCompany(null));
});

export const setAuth = createAction<PrepareAction<AuthModel>>(
  'user/setUser',
  (newAuth: AuthSlice, persist: boolean) => {
    persistAuth(newAuth, persist);

    return {
      payload: newAuth,
    };
  },
);

const authSlice: Slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => (state = action.payload.auth));
    builder.addCase(doUpdatedUserData.fulfilled, (state, action) => (state = action.payload.auth));
    builder.addCase(doLogout.fulfilled, () => {
      setAuth(null);
    });
    builder.addCase(setAuth, (state, action) => (state = action.payload));
  },
});

export default authSlice.reducer;
