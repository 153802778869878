import React, { useState } from 'react';
import { ShopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';

import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSelect } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';

import { useSelectCompany } from '@app/hooks/useSelectCompany';
import { CompanyModel } from '@app/services/companies';

export const CompanySelect: React.FC = () => {
  const { t } = useTranslation();
  const { mobileOnly } = useResponsive();
  const { isLoading, companyUid, canSeeInput, selectData, handleSelectCompany } = useSelectCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const width = mobileOnly ? '-webkit-fill-available' : '30rem';

  return (
    <>
      {canSeeInput ? (
        <>
          {mobileOnly ? (
            <>
              <BaseButton type={'text'} icon={<ShopOutlined onClick={() => setIsModalOpen(!isModalOpen)} />} />
            </>
          ) : (
            <BaseSelect
              width={width}
              placeholder={t('companies.finances.selectCompany')}
              disabled={isLoading}
              loading={isLoading}
              options={selectData.map((option) => ({ label: option.companyName, value: option.uid }))}
              onChange={(value) => handleSelectCompany(value as CompanyModel)}
              value={companyUid}
            />
          )}
          <BaseModal
            title={t('companies.finances.selectCompany')}
            open={isModalOpen}
            onCancel={() => setIsModalOpen(!isModalOpen)}
            footer={null}
          >
            <BaseSelect
              width={width}
              size="large"
              placeholder={t('companies.finances.selectCompany')}
              disabled={isLoading}
              loading={isLoading}
              options={selectData.map((option) => ({ label: option.companyName, value: option.uid }))}
              onChange={(value) => handleSelectCompany(value as CompanyModel)}
              value={companyUid}
            />
          </BaseModal>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};
