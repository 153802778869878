import { createAction, createSlice, PrepareAction, Slice } from '@reduxjs/toolkit';
import { persistCompany, readAuth, readCompany } from '@app/services/localStorage';

export type CompanyState = string | null;

const initialState: CompanyState = readCompany();

export const setCompany = createAction<PrepareAction<CompanyState>>('company/setCompany', (newCompanyId) => {
  const persist = readAuth(true);

  if (persist) {
    persistCompany(newCompanyId, true);
  } else {
    persistCompany(newCompanyId, false);
  }

  return {
    payload: newCompanyId,
  };
});

export const companySlice: Slice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCompany, (state, action) => {
      return (state = action.payload);
    });
  },
});

export default companySlice.reducer;
