import env from '../env';

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'organizza-dev.firebaseapp.com',
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: 'organizza-dev.appspot.com',
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export default firebaseConfig;
