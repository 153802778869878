import { UserModel } from '../firebase/users';
import env from '@app/config/env';
import { PersistAuth, PersistCompany, PersistUser, ReadAuth, ReadCompany, ReadUser } from './types';

const avatarImg = env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const mockUser: UserModel = {
  uid: '1',
  firstName: 'Chris',
  lastName: 'Johnson',
  imgUrl: avatarImg,
  userName: '@john1989',
  email: 'chris.johnson@altence.com',
  phone: '18143519459',
  birthdate: '01/26/2022',
  lang: 'br',
  city: 'London',
  address: '14 London Road',
  postalcode: '5211',
  roles: [],
  companies: [],
};

const mockAuth = {
  token: 'token',
  roles: ['admin'],
  permissions: [],
};

export const persistAuth: PersistAuth = () => {
  return;
};

export const readAuth: ReadAuth = () => {
  return mockAuth;
};

export const persistUser: PersistUser = () => {
  return;
};

export const readUser: ReadUser = () => {
  return mockUser;
};

export const readCompany: ReadCompany = () => {
  return '123';
};

export const persistCompany: PersistCompany = () => {
  return;
};

export const deleteAuth = (): void => localStorage.removeItem('auth');
export const deleteUser = (): void => localStorage.removeItem('user');
export const deleteCompany = (): void => localStorage.removeItem('company');
